* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    background-color: #f4f6f8;
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }
  
  #root {
    height: 100%;
  }